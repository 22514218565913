import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton,Box,Link } from '@mui/material';
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { FaHome, FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import {Button,Dialog,DialogActions,DialogContent, DialogTitle,DialogContentText,
  Checkbox,Select,MenuItem, InputLabel} from "@mui/material";
  import { useCookies } from 'react-cookie';

const Sidebar = ({ isOpen, onClose }) => {

const navigate = useNavigate()
const [logoutalert,setLogoutAlert] = React.useState(false)
const [cookies, setCookie, removeCookie] = useCookies(['value'])

const loggingout = () => {
    // localStorage.removeItem('userData')
    // navigate('/')
    removeCookie("value")
		localStorage.removeItem("userData");
		localStorage.removeItem("token");
}


const Alert = () => {
  setLogoutAlert(true)
}


const handleNavigation = () => {
  navigate('/table')
}





  return (
    <>
    <Drawer  variant="persistent" anchor="left" open={isOpen} >
      <MdKeyboardDoubleArrowLeft onClick={onClose} size={25} style={{ marginLeft: '97px', marginTop: '10px', cursor: 'pointer' }} />

      {/* <Box style={{
          display: "flex",
          justifyContent: "center",
          flexGrow: 1,
          width: '100%'
        }}>
          <Link className='logoDiv' >
            <img src={require("../Images/nature.jpg")} className='logo' ></img>
          </Link>
        </Box> */}
      <List >


<ListItem onClick={handleNavigation} style={{ cursor: 'pointer' }}>
      <ListItemIcon>
        <FaHome color='black' size={24} />
      </ListItemIcon>
      <ListItemText primary="Trades" style={{ color: 'black' }} onClick={handleNavigation} />
    </ListItem>

    

        <ListItem style={{ cursor: 'pointer' }} onClick={() => Alert()}>
          <ListItemIcon>
            <MdLogout color='black' size={24} />
          </ListItemIcon>
          <ListItemText primary="Logout" style={{ color: 'black' }} onClick={() => Alert()} />
        </ListItem>

      </List>
    </Drawer>


    <Dialog
      open={logoutalert}
      onClose={() => setLogoutAlert(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title" style={{ color: 'black', fontWeight: 'bold' }}>{"Logout"}<IoMdClose style={{ marginLeft: "280px" }} color="gray" size={22} onClick={() => setLogoutAlert(false)} /></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Would you like to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: 'lightgrey', color: 'white', fontWeight: 'bold' }} onClick={() => setLogoutAlert(false)} color="primary">
            No
          </Button>
          <Button style={{ backgroundColor: '#7E0FF2', color: 'white', fontWeight: 'bold' }} onClick={()=>loggingout()} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog></>
    
  );
};

export default Sidebar;
