import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import menu from './../assets/images/menu.svg'
import LockIcon from '@mui/icons-material/Lock';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LockResetIcon from '@mui/icons-material/LockReset';
import LogoutIcon from '@mui/icons-material/Logout';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
const drawerWidth = 300;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer(type) {
    let navigate = useNavigate()
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const [indexval, setIndexval] = React.useState(0);
    


    const [cookies, setCookie, removeCookie] = useCookies(['value'])
	// let loginData = JSON.stringify(localStorage.getItem('userData'))
	let loginData = JSON.parse(localStorage.getItem('userData'))
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(!open);
    };
    React.useEffect(() => {
        const body = document.querySelector('body');
        if (open) {
          body.classList.add('sidebarOpen');
          body.classList.remove('sidebarClose');
        } else {
          body.classList.add('sidebarClose');
          body.classList.remove('sidebarOpen');
        }
      }, [open]);

      const handleLogout = () => {
        removeCookie("value")
		localStorage.removeItem("userData");
		localStorage.removeItem("token");
		navigate("/")
      }

      React.useEffect(() => {
		setIndexval(type.type);
	}, [type])

      const handleChanePage =(text,index) =>{
        setIndexval(index) 
          if(text == 'Strangle Strategy'){
            navigate("/strangle") 
          }
          else if(text == 'ButterFly'){
            navigate("/butterfly") 
          }

      }


   
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
         
            <Drawer variant="permanent" open={open} className={open ? 'sidebarOpen sidebar_container' : 'sidebarClose sidebar_container'}>
                <Box className='sidebar_in'>
                    <DrawerHeader>
                        <Typography component='h5'>APEX</Typography>
                        <IconButton onClick={handleDrawerClose}>
                            <img src={menu} />
                        </IconButton>
                    </DrawerHeader>
                    <Box className='sidebar_prof'>
                        <Box class="pro_img">
                            <img width="100%" src="https://apex.scanofy.com/public/assets/images/prof.png"/>
                        </Box>
                        <Box class="name">{loginData && loginData.email ? loginData.email.split("@")[0]:""}</Box>
                    </Box>
                    <List>

                         {['Strangle Strategy', 'ButterFly'].map((text, index) => ( 
                      
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton className={indexval == index ? 'colorchangedrawer':""} onClick={() => handleChanePage(text, index)}
                                    sx={{minHeight: 48,justifyContent: open ? 'initial' : 'center', px: 2.5,}}>
                                    <ListItemIcon sx={{minWidth: 0,mr: open ? 3 : 'auto',justifyContent: 'center',}} >
                            
                                    {index % 2 === 0 ? <LockIcon /> : <LockResetIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>


                            // <ListItem disablePadding sx={{ display: 'block' }}>
                            //     <ListItemButton onClick={handleButterFly}
                            //         sx={{
                            //             minHeight: 48,
                            //             justifyContent: open ? 'initial' : 'center',
                            //             px: 2.5,
                            //         }}
                            //     >
                            //         <ListItemIcon
                            //             sx={{
                            //                 minWidth: 0,
                            //                 mr: open ? 3 : 'auto',
                            //                 justifyContent: 'center',
                            //             }}
                            //         >
                            //             <LockResetIcon />
                            //         </ListItemIcon>
                            //         <ListItemText primary={'ButterFly'} sx={{ opacity: open ? 1 : 0 }} />
                            //     </ListItemButton>
                            // </ListItem>

                         ))} 
                    </List>



                    <Divider />
                    {/* <List>
                        {['Administrator team', 'Authority', 'Scripts By Group','Algos','Brokerage And Taxes','Client Accounts','Reports'].map((text, index) => (
                            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton 
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {index % 2 === 0 ? 
                                        <InboxIcon /> 
                                        : <SupervisorAccountIcon />
                                        }
                                    </ListItemIcon>
                                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List> */}


                    <ListItem  disablePadding sx={{ display: 'block' }}>
                                <ListItemButton onClick={handleLogout}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Logout"} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                </Box>
            </Drawer>

        </Box>
    );
}
