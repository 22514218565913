import logo from './logo.svg';
import './App.css';
import '../src/assets/css/custom.css';
import '../src/assets/css/responsive.css';
import React from 'react';
import Routers from './routes'
import { SnackbarProvider } from 'notistack';

function App() {




  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={3000} anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }} >
      <Routers />
    </SnackbarProvider>
    
  );
}

export default App;
