import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { CardActionArea, FormLabel } from '@mui/material';
import '../App.css'
import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, TextField, Typography, IconButton, OutlinedInput } from '@mui/material'
import { useSnackbar, SnackbarProvider } from 'notistack'
import { useNavigate } from 'react-router-dom';
import socketio from 'socket.io-client';
import Stack from '@mui/material/Stack';
import { VisibilityOff, Visibility, Lock } from '@mui/icons-material';
import { BusinessOutlined, Person } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import CircleIcon from '@mui/icons-material/Circle';
import EmailIcon from '@mui/icons-material/Email';
import loginImg from '../../src/assets/images/loginImg.png'

const SegmentApp = () => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar()
	const [data, setData] = React.useState([{ exchangeInstrumentID: "", exchangeSegment: "" }])
	const [error, setError] = React.useState({ instrumentID: "" })
	const [data2, setData2] = React.useState([])
	const [enable2, setEnable2] = React.useState(false)
	const [instrumentID, setInstrumentID] = React.useState('')
	const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

	const [email, setEmail] = React.useState('')
	const [password, setPassword] = React.useState('')


	const [values, setValues] = React.useState({
		showPassword: false,
		showRePassword: false
	});


	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		});
	};

	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleSubmit(e)
		}
	}



	const [emailerror, setEmailerror] = React.useState('')
	const [passworderror, setPassworderror] = React.useState('')
	const [buttonview, setButtonview] = React.useState(false)

	const Validation = () => {
		if (!email) {
			setEmailerror("This field is required")
			return false
		}
		if (!password) {
			setPassworderror("This field is required")
			return false
		}
		return true
	}

	const handleSubmit = async() => {

		let Valid = Validation();
		if (Valid) {
			setButtonview(true)
			let body = {
				email: email.trim(),
				password: password
			}

			await axios.post(process.env.REACT_APP_BASE_URL +  `marketlogin`, body)
				.then((res) => {
					setButtonview(false)
					enqueueSnackbar("Login successfully", { variant: 'success' })
					setEmail('')
					setPassword('')
					console.log(res.data, "login");
					localStorage.setItem("userData", JSON.stringify(res.data.admin))
					setCookie('value', res.data.token);
					navigate('/strangle');
				})
				.catch((error) => {
					setButtonview(false)
					enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
					console.log(error, "error");
				})
		}

	}


	return (
		<>
			<Box className='login_container'>
				<Box className="mainnew1">
					<Grid container>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className='left_content'>
								<Typography component='h6' className='company-name semibold fz-18'><CircleIcon className='fz-10' />  Apex</Typography>
								<Box className='content_section'>
									<Typography component='h6' className='login-user-type'>Admin Login</Typography>

									<Stack spacing={2}>
										<FormLabel className='label'>Email</FormLabel>
										<TextField fullWidth
											onChange={(e) => { setEmail(e.target.value); setEmailerror('') }}
											value={email}
											id="outlined-basic1"
											variant="outlined"
											className='textfield'
											onKeyDown={_handleKeyDown}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<EmailIcon className='fz-16 text-primary' />
													</InputAdornment>
												),
											}}
										/>
										{emailerror && <p style={{ color: 'red' }} className="fz-13 m-0" >{emailerror}</p>}
										<FormLabel className='label'>Password</FormLabel>
										<FormControl variant="outlined" fullWidth className='m-0'>
											<OutlinedInput
												id="outlined-adornment-password"
												placeholder="Password"
												type={values.showPassword ? 'text' : 'password'}
												onChange={(e) => { setPassword(e.target.value); setPassworderror('') }}
												value={password}
												className='textfield'
												onKeyDown={_handleKeyDown}

												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleClickShowPassword}
															edge="end"
														>
															{values.showPassword ? <VisibilityOff className='fz-16 text-primary' /> : <Visibility className='fz-16 text-primary' />}
														</IconButton>
													</InputAdornment>
												}
												startAdornment={
													<InputAdornment position="start">
														<Lock className='fz-16 text-primary' />
													</InputAdornment>
												}
												label="Password"
											/>
										</FormControl>
										{passworderror && <p style={{ color: 'red' }} className="fz-13 m-0">{passworderror}</p>}

									</Stack>

									{buttonview == false ?

										<Button className="themeBtn w100 my-40 " onClick={handleSubmit}>
											Login
										</Button>
										:
										<div class="text-center themeBtn mt-30" >
											<a class="" >
												<span class="text-container">
													<span class="text">Please wait...</span>
												</span>
											</a>
										</div>}
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className='text-center ryt_content'>
								<img src={loginImg} className='loginImg' />
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>

		</>
	)
}
export default SegmentApp;
