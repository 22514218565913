import React, { useState, useEffect, useId } from "react";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Box } from '@mui/system'
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Modal, TextField,Button, IconButton, Paper, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,Checkbox, Select, MenuItem, InputLabel, Grid, Card, Input, FormControl} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MiniDrawer from "../Components/Drawer";
import socketio from 'socket.io-client';


const DashBoard = () => {
	let loginData = localStorage.getItem('userData')
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [test, setTest] = useState(false);
	const [instrumentID, setInstrumentID] = React.useState('')
	const [search, setSearch] = React.useState('')
	const [data, setData] = React.useState([])
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const [filter, setFilter] = React.useState('');
	const [filter1, setFilter1] = React.useState('');

	const [currentPage, setCurrentPage] = useState(1);
	const totalDataCount = data?.length
	const startIndex = (currentPage - 1) * rowsPerPage;
	const endIndex = startIndex + rowsPerPage;
	const currentData = data.slice(startIndex, endIndex);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	const [showSidebar, setShowSidebar] = useState(true);
	const toggleSidebar = () => {
		setShowSidebar(!showSidebar);
	};

	const FilterMethod = (e) => {
		setFilter(e.target.value)
		setCurrentPage(1)
		setRowsPerPage(20)
	}





	const FilterMethod1 = (e) => {
		setFilter1(e.target.value)
		setCurrentPage(1)
		setRowsPerPage(20)
	}
	const handleSearchChange = (e) => {
		setSearch(e.target.value);
		setCurrentPage(1)
		setRowsPerPage(20)
	};




	useEffect(() => {
		const socket = socketio(process.env.REACT_APP_BASE_URL, {
			query: {
				search: search,
				series: filter,
				strikeprice: filter1,
			}
		});

		socket.on('strangless', (data) => {
			setData(data )
		});




		socket.on('connect', () => {
			console.log('Connected to server');
		});
	}, [search, filter,filter1]);



	const RemoveReset = () => {
		setSearch('')
		setPage(0)
		setFilter('');
		setFilter1('');
	}




	return (
		<div>
			<Box className='page_main'>
				<MiniDrawer type={0} />
				<Box className='content-wrapper'>
					<Box className='customCard'>

						<div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<span className="fz-14 mr-10">Search:</span>
								<input
									type="text"
									placeholder="Search..."
									value={search}
									onChange={handleSearchChange}
									className="input"
								/>
							</div>
							<Box className='flex-center'>
								<InputLabel id="demo-simple-select-label" className="fz-14 mr-10">Series:</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={filter}
									onChange={FilterMethod}
									name="email"
									className='select'
									displayEmpty
									autoWidth
								>
									<MenuItem disabled value="">
										<em>Series</em>
									</MenuItem>
									<MenuItem value="OPTSTK">OPTSTK</MenuItem>

								</Select>
							</Box>

							<Box className='flex-center'>
								<InputLabel id="demo-simple-select-label" className="fz-14 mr-10">StrikePrice:</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={filter1}
									onChange={FilterMethod1}
									name="email"
									className='select'
									displayEmpty
									autoWidth
								>
									<MenuItem disabled value="">
										<em>StrikePrice</em>
									</MenuItem>
									<MenuItem value="1000">0-1000</MenuItem>
									<MenuItem value="2000">1001-2000</MenuItem>
									<MenuItem value="3000">2001-3000</MenuItem>
									<MenuItem value="5000">3001-5000</MenuItem>
								</Select>
							</Box>

							{filter != '' || search || filter1 !== '' ?
								<Button onClick={RemoveReset} className="themeBtn">
									Clear Filter
							</Button>
								: ""}
						</div>

						<TableContainer component={Paper} style={{ marginTop: 10 }}>
							<Table>
								<TableHead>
									<TableRow>

										<TableCell  style={{ width: '10%',whiteSpace:'nowrap'}}>Script Name</TableCell>


										<TableCell align="right" >Series</TableCell>
										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>SPOT CMP</TableCell>
										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>ATM strike</TableCell>
										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Lot Size</TableCell>
										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>100Day IV</TableCell>


										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Current IV</TableCell>
										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Higher IV</TableCell>
										<TableCell align="right">Expiry Date</TableCell>
										<TableCell align="right" >Days to Expiry</TableCell>
										<TableCell align="right">Trading Days/Year</TableCell>
										<TableCell align="right" >Yearly Trading Days/Days to expiry</TableCell>

										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Square root</TableCell>
										<TableCell align="right"  >Higher IV/Square Root</TableCell>

										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Strangle CE value</TableCell>
										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Strangle PE value</TableCell>
										<TableCell align="right"  >MAX Call(Strike Price)</TableCell>
										<TableCell align="right"  >MAX Put (Strike Price)</TableCell>

										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Highest Call</TableCell>
										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Lowest Put</TableCell>

										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Call BID</TableCell>
										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Put BID</TableCell>
										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Call Price</TableCell>
										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Put Price</TableCell>

										<TableCell align="right"  style={{ width: '10%',whiteSpace:'nowrap'}}>Total Premium</TableCell>
										<TableCell align="right"  >Total Investment</TableCell>
										<TableCell align="right">ROI</TableCell>
									</TableRow>
								</TableHead>
								{data && data?.length > 0 ? data.map((row, i) => (
									<TableBody>
										<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

											<TableCell align="right">{row.Name ? row.Name : ""}</TableCell>
											<TableCell align="right">{row.Series ? row.Series : ""}</TableCell>
											<TableCell align="right">{row.CMP_sptiprice ? row.CMP_sptiprice : ""}</TableCell>
											
											<TableCell align="right">{row.ATM_StikePrice ? parseFloat(row.ATM_StikePrice).toFixed(2) : ""}</TableCell>


											
											<TableCell align="right">{row.Lotsize ? row.Lotsize : ""}</TableCell>
											<TableCell align="right">{row.HundredIV ? row.HundredIV : ""}</TableCell>
											<TableCell align="right">{row.currentIV == 0 ? '0' : row.currentIV ? parseFloat(row.currentIV).toFixed(2) : ""}</TableCell>
											<TableCell align="right">{row.HigherIV ? row.HigherIV : ""}</TableCell>
											<TableCell style={{width:'12%'}} align="right">{row.ExpiryDate ? row.ExpiryDate : ""}</TableCell>
											<TableCell align="right">{row.DateDifference ? row.DateDifference : ""}</TableCell>
											<TableCell align="right">{row.tradingdays ? row.tradingdays : ""}</TableCell>
											<TableCell align="right">{row.DaystoExpire ? parseFloat(row.DaystoExpire).toFixed(2) : ""}</TableCell>

											<TableCell align="right">{row.IVSquare ? parseFloat(row.IVSquare).toFixed(2) : ""}</TableCell>
											<TableCell align="right">{row.HIgherivdevidesquare ? parseFloat(row.HIgherivdevidesquare).toFixed(2) : ""}</TableCell>

										
										
										
											<TableCell align="right">{row.strangleValuece ? parseFloat(row.strangleValuece).toFixed(2) : "-"}</TableCell>
											<TableCell align="right">{row.Stranglevaluepe ? parseFloat(row.Stranglevaluepe).toFixed(2) : "-"}</TableCell>

											<TableCell align="right">{row.Maxcall ? row.Maxcall : ""}</TableCell>
											<TableCell align="right">{row.Maxput ? row.Maxput : ""}</TableCell>

											<TableCell align="right">{row.Highestcall ? parseFloat(row.Highestcall).toFixed(2) : ""}</TableCell>
											<TableCell align="right">{row.Lowestput ? parseFloat(row.Lowestput).toFixed(2) : ""}</TableCell>

											
											<TableCell align="right">{row.callBID ? parseFloat(row.callBID).toFixed(2) : ""}</TableCell>
											<TableCell align="right">{row.putBID ? parseFloat(row.putBID).toFixed(2) : ""}</TableCell>
											<TableCell align="right">{row.callPRICE != null ? parseFloat(row.callPRICE).toFixed(2) : "-"}</TableCell>
											<TableCell align="right">{row.putPRICE != null ? parseFloat(row.putPRICE).toFixed(2) : "-"}</TableCell>

											<TableCell align="right">{row.TotalPremium ? parseFloat(row.TotalPremium).toFixed(2) :""}</TableCell>
											<TableCell align="right">{row.TotalInvestment ? parseFloat(row.TotalInvestment).toFixed(2) :""}</TableCell>
											<TableCell align="right">{row.ROI ? parseFloat(row.ROI).toFixed(2) :""}</TableCell>
										</TableRow>

									</TableBody>

								)) : <TableBody>
									<TableCell colSpan={7} className="text-center">

										No data found

								</TableCell>
								</TableBody>}

							</Table>
						</TableContainer>
						

						{/* secondold */}
						{/* <TableContainer component={Paper} style={{ marginTop: 10 }}>
							<Table>
								<TableHead>
									<TableRow>

										<TableCell >Script Name</TableCell>


										<TableCell align="right">Series</TableCell>
										<TableCell align="right">SPOT CMP</TableCell>
										<TableCell align="right">ATM strike</TableCell>
										<TableCell align="right">Lot Size</TableCell>
										<TableCell align="right">100Day IV</TableCell>


										<TableCell align="right">Current IV</TableCell>
										<TableCell align="right">Higher IV</TableCell>
										<TableCell align="right">Expiry Date</TableCell>
										<TableCell align="right">Days to Expiry</TableCell>
										<TableCell align="right">Trading Days/Year</TableCell>
										<TableCell align="right">Yearly Trading Days/Days to expiry</TableCell>

										<TableCell align="right">Square root</TableCell>
										<TableCell align="right">Strangle CE value</TableCell>
										<TableCell align="right">Strangle PE value</TableCell>
										<TableCell align="right">MAX Call(Strike Price)</TableCell>
										<TableCell align="right">MAX Put (Strike Price)</TableCell>

										<TableCell align="right">Highest Call</TableCell>
										<TableCell align="right">Lowest Put</TableCell>

										<TableCell align="right">Call BID</TableCell>
										<TableCell align="right">Put BID</TableCell>
										<TableCell align="right">Call Price</TableCell>
										<TableCell align="right">Put Price</TableCell>

										<TableCell align="right">Total Premium</TableCell>
										<TableCell align="right">Total Investment</TableCell>
										<TableCell align="right">ROI</TableCell>
									</TableRow>
								</TableHead>
								{currentData && currentData?.length > 0 ? currentData.map((row, i) => (
									console.log(row, "rrrroooooowwwwwwww"),
									<TableBody>
										<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

											<TableCell align="right">{row.Script_name ? row.Script_name : ""}</TableCell>
											<TableCell align="right">{row.Series ? row.Series : ""}</TableCell>

											<TableCell align="right">{row.LastTradePrice ? row.LastTradePrice : ""}</TableCell>
											<TableCell align="right">{row.ATM_StikePrice ? parseFloat(row.ATM_StikePrice).toFixed(2) : ""}</TableCell>
											<TableCell align="right">{row.LotSize ? row.LotSize : ""}</TableCell>
											<TableCell align="right">{row.HundredIV ? row.HundredIV : ""}</TableCell>
											<TableCell align="right">{row.IV ? parseFloat(row.IV).toFixed(2) : ""}</TableCell>
											<TableCell align="right">{row.HigherIV ? row.HigherIV : ""}</TableCell>

											
											<TableCell align="right">{row.ExpiryDate ? row.ExpiryDate : ""}</TableCell>
											<TableCell align="right">{row.DaystoExpire ? row.DaystoExpire : ""}</TableCell>
											<TableCell align="right">{row.tradingdays ? row.tradingdays : ""}</TableCell>
											<TableCell align="right"></TableCell>
											<TableCell align="right">{row.IVSquare ? parseFloat(row.IVSquare).toFixed(2) : ""}</TableCell>
											<TableCell align="right">{row.strangleValuece ? parseFloat(row.strangleValuece).toFixed(2) : ""}</TableCell>
											<TableCell align="right">{row.Stranglevaluepe ? parseFloat(row.Stranglevaluepe).toFixed(2) : ""}</TableCell>

											<TableCell align="right">{row.Maxcall ? row.Maxcall : ""}</TableCell>
											<TableCell align="right">{row.Maxput ? row.Maxput : ""}</TableCell>

											<TableCell align="right">{row.Highestcall ? row.Highestcall : ""}</TableCell>
											<TableCell align="right">{row.LowestPut ? parseFloat(row.LowestPut).toFixed(2) : ""}</TableCell>


											<TableCell align="right"></TableCell>
											<TableCell align="right"></TableCell>
											<TableCell align="right"></TableCell>
											<TableCell align="right"></TableCell>

											<TableCell align="right"></TableCell>
											<TableCell align="right"></TableCell>
											<TableCell align="right"></TableCell>
										</TableRow>

									</TableBody>

								)) : <TableBody>
									<TableCell colSpan={7} className="text-center">

										No data found

								</TableCell>
								</TableBody>}

							</Table>
						</TableContainer> */}

                      {/* //first old */}
						{/* <TableContainer component={Paper} style={{ marginTop: 10 }}>
							<Table>
								<TableHead>
									<TableRow>

										<TableCell >Script Name</TableCell>


										<TableCell align="right">Series</TableCell>
										<TableCell align="right">SPOT CMP</TableCell>
										<TableCell align="right">ATM strike</TableCell>
										<TableCell align="right">Lot Size</TableCell>
										<TableCell align="right">100Day IV</TableCell>


										<TableCell align="right">Current IV</TableCell>
										<TableCell align="right">Higher IV</TableCell>
										<TableCell align="right">Expiry Date</TableCell>
										<TableCell align="right">Days to Expiry</TableCell>
										<TableCell align="right">Trading Days/Year</TableCell>
										<TableCell align="right">Yearly Trading Days/Days to expiry</TableCell>

										<TableCell align="right">Square root</TableCell>
										<TableCell align="right">Strangle CE value</TableCell>
										<TableCell align="right">Strangle PE value</TableCell>
										<TableCell align="right">MAX Call(Strike Price)</TableCell>
										<TableCell align="right">MAX Put (Strike Price)</TableCell>

										<TableCell align="right">Highest Call</TableCell>
										<TableCell align="right">Lowest Put</TableCell>

										<TableCell align="right">Call BID</TableCell>
										<TableCell align="right">Put BID</TableCell>
										<TableCell align="right">Call Price</TableCell>
										<TableCell align="right">Put Price</TableCell>

										<TableCell align="right">Total Premium</TableCell>
										<TableCell align="right">Total Investment</TableCell>
										<TableCell align="right">ROI</TableCell>
									</TableRow>
								</TableHead>
								{currentData && currentData?.length > 0 ? currentData.map((row, i) => (
									console.log(row, "rrrroooooowwwwwwww"),
									<TableBody>
										<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

											<TableCell align="right">{row.Name ? row.Name : ""}</TableCell>
											<TableCell align="right">{row.Series ? row.Series : ""}</TableCell>
											<TableCell align="right">{row.LastTradePrice ? row.LastTradePrice : ""}</TableCell>
											<TableCell align="right">{row.StrikePrice ? row.StrikePrice : ""}</TableCell>
											<TableCell align="right">{row.LotSize ? row.LotSize : ""}</TableCell>
											<TableCell align="right">{row._100DayIV ? row._100DayIV : ""}</TableCell>
											<TableCell align="right">{row.IV ? parseFloat(row.IV).toFixed(2) : ""}</TableCell>
											<TableCell align="right">{row.HigherIV ? row.HigherIV : ""}</TableCell>
											<TableCell align="right"></TableCell>
											<TableCell align="right">{row.DaysToExpire ? row.DaysToExpire : ""}</TableCell>
											<TableCell align="right"></TableCell>
											<TableCell align="right"></TableCell>
											<TableCell align="right">{row.SquareRoot ? parseFloat(row.SquareRoot).toFixed(2) : ""}</TableCell>
											<TableCell align="right">{row.StrangleCE ? parseFloat(row.StrangleCE).toFixed(2) : ""}</TableCell>
											<TableCell align="right">{row.StranglePE ? parseFloat(row.StranglePE).toFixed(2) : ""}</TableCell>

											<TableCell align="right">{row.MaxCall ? row.MaxCall : ""}</TableCell>
											<TableCell align="right">{row.MaxPut ? row.MaxPut : ""}</TableCell>

											<TableCell align="right">{row.HighestCall ? row.HighestCall : ""}</TableCell>
											<TableCell align="right">{row.LowestPut ? parseFloat(row.LowestPut).toFixed(2) : ""}</TableCell>


											<TableCell align="right"></TableCell>
											<TableCell align="right"></TableCell>
											<TableCell align="right"></TableCell>
											<TableCell align="right"></TableCell>

											<TableCell align="right"></TableCell>
											<TableCell align="right"></TableCell>
											<TableCell align="right"></TableCell>
										</TableRow>

									</TableBody>

								)) : <TableBody>
									<TableCell colSpan={7} className="text-center">

										No data found

								</TableCell>
								</TableBody>}

							</Table>
						</TableContainer> */}
					</Box>
                      {/* //working */}
					{/* <div className="pagination-container" style={{marginTop:10}}>
						<button className="pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous </button>
						<span className="pagination-page">Page {currentPage}</span>
						<button className="pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={endIndex >= data.length}>Next</button>
					</div> */}

					{/* <div>
						<button style={{cursor:'pointer'}} onClick={() => handlePageChange(currentPage - 1)}
							disabled={currentPage === 1}>Previous</button>
						<span>Page {currentPage}</span>
						<button style={{cursor:'pointer'}} onClick={() => handlePageChange(currentPage + 1)}
							disabled={endIndex >= data.length}>Next</button>
					</div>  */}


					{/* <Button onClick={() => handlePageChange(currentPage - 1)}
							disabled={currentPage === 1} className="themeBtn18">
									Previous
							</Button>
							<span>Page {currentPage}</span>
							<Button onClick={() => handlePageChange(currentPage + 1)}
							disabled={endIndex >= data.length} className="themeBtn18">
									Next
							</Button> */}

				</Box>

			</Box>





		</div>
	);
};



export default DashBoard;





