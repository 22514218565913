import React,{Suspense} from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from '../Authentication/login'
import DashBoard from '../DashBoard'
import ButterFly from '../Butterfly'

import Sidebar from '../Components/Sidebar'
import PrivateRoute from '../Components/PrivateRoute'

const Routers = () => {
    return(
        <Router>
            <Suspense>
            <Routes>
           
            <Route exact path='/' element={<Login/>}></Route>
            <Route exact path='/strangle' element={<PrivateRoute><DashBoard/></PrivateRoute>}></Route>
            <Route exact path='/butterfly' element={<PrivateRoute><ButterFly/></PrivateRoute>}></Route>
            {/* <Route exact path='/butterfly' element={<ButterFly/>}></Route> */}
            
            <Route exact path='/Components/Sidebar' element={<Sidebar/>}></Route>
        
            
            </Routes>
            </Suspense>
        </Router>
    )
}

export default Routers;