import React, { useState, useEffect, useId } from "react";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Box } from '@mui/system'
import TablePagination from "@mui/material/TablePagination";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Modal, TextField, Button, IconButton, Paper, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Checkbox, Select, MenuItem, InputLabel, Grid, Card, Input, FormControl, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MiniDrawer from "../Components/Drawer";
// import socketio from 'socket.io-client';
import io from 'socket.io-client';
import moment from 'moment'
import socketio from 'socket.io-client';

const ButterFly = () => {
	let loginData = localStorage.getItem('userData')
	const [strike, setStrike] = useState([]);
	const [spotprice, setSpotprice] = React.useState('');
	const [instrumentID, setInstrumentID] = React.useState('26000')
	const [Number, setNumber] = React.useState('')
	const [alldata, setAllData] = React.useState([])
	const [currentivdata, setCurrentivdata] = React.useState([])
	const [dataPe, setDataPe] = React.useState([])
	const [calcshow, setCalcshow] = React.useState([])
	const [newData, setNewData] = React.useState([])
	const [optionChain, setoptionChain] = React.useState([])
	const [optionChaintable, setOptionchaintable] = React.useState([])
	const { enqueueSnackbar } = useSnackbar();
	const [symbol, setSymbol] = React.useState('NIFTY')
	const [nearval, setNearval] = React.useState('')
	const niftyvalue = 26000
	const [datearray, setDatearray] = useState([]);
	const [expirydate, setExpiryDate] = React.useState('2024-06-13')

	const [stepvalue, setStepvalue] = useState('50')
	const [straddleval, setStraddleval] = useState('')
	const [value, setValue] = useState(false)

	useEffect(() => {
		if (expirydate != '') {
			const socket = socketio(process.env.REACT_APP_BASE_URL, {
				query: {
					symbol: symbol,
					expirydate: expirydate,
					stepvalue: stepvalue,
					values: value
				}
			});

			// socket.on('expiredate', (data) => {
			// 	console.log(data, "expiredateexpiredateexpiredate")
			// 	// setStrike(data)
			// });
            //  console.log(socket,"socketsocket");
			socket.on('marketdata', (data) => {
				setStrike(data)
			});

			socket.on('spotdata', (data) => {
				// console.log(data,"datadata");
				setNewData(data)
			});

			socket.on('connect', () => {
				console.log('Connected to server');
			});
			socket.on('dicconnect', () => {
				console.log('DISConnected to server');
			});
		}

	}, [symbol, expirydate, stepvalue]);



	// React.useMemo(() => {
	// 	var strikeMap = {};
	// 	optionChaintable.forEach(item => {
	// 		if (!strikeMap[item.StrikePrice]) {
	// 			strikeMap[item.StrikePrice] = {};
	// 		}
	// 		strikeMap[item.StrikePrice][item.OptionType] = item.LastTradedPrice;
	// 	});


	// 	optionChain.forEach(item => {
	// 		var strikeKey = item.StrikePrice;
	// 		if (strikeMap[strikeKey]) {
	// 			if (strikeMap[strikeKey][3]) {
	// 				item.LastTradedPriceopt3 = strikeMap[strikeKey][3];
	// 			}
	// 			if (strikeMap[strikeKey][4]) {
	// 				item.LastTradedPriceopt4 = strikeMap[strikeKey][4];
	// 			}
	// 		}
	// 	});
	// 	setoptionChain(optionChain)
	// }, [optionChaintable])




	// React.useMemo(() => {
	// 	if (currentivdata) {
	// 		var strikeMap = {};
	// 		currentivdata.forEach(item => {
	// 			if (!strikeMap[item.StrikePrice]) {
	// 				strikeMap[item.StrikePrice] = {};
	// 			}
	// 			strikeMap[item.StrikePrice][item.OptionType] = item.CurrentIV;
	// 		});


	// 		optionChain.forEach(item => {
	// 			var strikeKey = item.StrikePrice;
	// 			if (strikeMap[strikeKey]) {
	// 				if (strikeMap[strikeKey][3]) {
	// 					item.currentIvoption3 = strikeMap[strikeKey][3];
	// 				}
	// 				if (strikeMap[strikeKey][4]) {
	// 					item.currentIvoption4 = strikeMap[strikeKey][4];
	// 				}
	// 			}
	// 		});
	// 		setoptionChain(optionChain)
	// 	}

	// }, [currentivdata])



	// React.useMemo(() => {
	// 	if (calcshow?.length > 0) {

	// 		var arraytype3 = calcshow.filter((data) => data.optiontype == '3')
	// 		var arraytype4 = calcshow.filter((data) => data.optiontype == '4')


	// 		var strikeMap = {};
	// 		arraytype3.forEach(item => {
	// 			if (!strikeMap[item.strikedata]) {
	// 				strikeMap[item.strikedata] = {};
	// 			}
	// 			strikeMap[item.strikedata][item.optiontype] = item.calculatedValue;
	// 		});


	// 		alldata.forEach(item => {
	// 			var strikeKey = item.StrikePrice;
	// 			if (strikeMap[strikeKey]) {
	// 				if (strikeMap[strikeKey][3]) {
	// 					item.ButterFlyCE = strikeMap[strikeKey][3];
	// 				}
	// 			}
	// 		});
	// 		setAllData(alldata)



	// 		var strikeMap = {};
	// 		arraytype4.forEach(item => {
	// 			if (!strikeMap[item.strikedata]) {
	// 				strikeMap[item.strikedata] = {};
	// 			}
	// 			strikeMap[item.strikedata][item.optiontype] = item.calculatedValue;
	// 		});


	// 		dataPe.forEach(item => {
	// 			var strikeKey = item.StrikePrice;
	// 			if (strikeMap[strikeKey]) {
	// 				if (strikeMap[strikeKey][4]) {
	// 					item.ButterFlyPE = strikeMap[strikeKey][4];
	// 				}
	// 			}
	// 		});
	// 		setDataPe(dataPe)

	// 	}


	// }, [calcshow])

	// console.log(strike,"strikestrikestrike");




	const calculateValuesforCE = (data, stepSize) => {

		let result = [];
		for (let i = 0; i < data.length; i++) {
			const currentItem = data[i];
			const currentPrice = currentItem.Touchline.LastTradedPrice;
			const currentStrike = parseInt(currentItem.StrikePrice);

			const abovePrice = currentStrike + parseInt(stepSize);
			const belowPrice = currentStrike - parseInt(stepSize);


			const aboveItem = data.find(item => parseInt(item.StrikePrice) === abovePrice);
			const belowItem = data.find(item => parseInt(item.StrikePrice) === belowPrice);

			if (aboveItem && belowItem) {
				const calculatedValue = ((aboveItem.Touchline.LastTradedPrice + belowItem.Touchline.LastTradedPrice) - (2 * currentPrice));
				result.push({
					Touchline: currentItem.Touchline,
					_id: currentItem._id,
					ExchangeSegment: currentItem.ExchangeSegment,
					ExchangeInstrumentID: currentItem.ExchangeInstrumentID,
					ContractExpiration: currentItem.ContractExpiration,
					LotSize: currentItem.LotSize,
					Name: currentItem.Name,
					OptionType: currentItem.OptionType,
					StrikePrice: currentItem.StrikePrice,
					OpenIntrest: currentItem.OpenIntrest,
					calculatedValue


				});
			}
		}

		return result;
	};






	const calculateValuesforPE = (data, stepSize) => {

		let result = [];
		for (let i = 0; i < data.length; i++) {
			const currentItem = data[i];
			const currentPrice = currentItem.Touchline.LastTradedPrice;
			const currentStrike = parseInt(currentItem.StrikePrice);

			const abovePrice = currentStrike + parseInt(stepSize);
			const belowPrice = currentStrike - parseInt(stepSize);


			const aboveItem = data.find(item => parseInt(item.StrikePrice) === abovePrice);
			const belowItem = data.find(item => parseInt(item.StrikePrice) === belowPrice);

			if (aboveItem && belowItem) {
				const calculatedValue = ((aboveItem.Touchline.LastTradedPrice + belowItem.Touchline.LastTradedPrice) - (2 * currentPrice));
				result.push({
					Touchline: currentItem.Touchline,
					_id: currentItem._id,
					ExchangeSegment: currentItem.ExchangeSegment,
					ExchangeInstrumentID: currentItem.ExchangeInstrumentID,
					ContractExpiration: currentItem.ContractExpiration,
					LotSize: currentItem.LotSize,
					Name: currentItem.Name,
					OptionType: currentItem.OptionType,
					StrikePrice: currentItem.StrikePrice,
					OpenIntrest: currentItem.OpenIntrest,
					calculatedValue


				});
			}
		}

		return result;
	};


	React.useMemo(() => {
		var abc = newData?.length > 0 && newData.filter((data) => data.ExchangeInstrumentID == instrumentID)
		if (abc?.length > 0) {
			var spotval = abc[0].Touchline.LastTradedPrice
			setSpotprice(spotval)
			const stepSize = stepvalue;
			var testall = strike;
			let nearestValue = null;
			let minDifference = Infinity;
			testall.forEach((item, index) => {
				let difference = Math.abs(item.StrikePrice - spotval);
				if (difference < minDifference) {
					minDifference = difference;
					nearestValue = item.StrikePrice;
				}
			});

			setNearval(nearestValue)

			if (nearestValue) {
				var test = strike
				var arraytype3 = test.filter((data) => data.OptionType == '3')
				var arraytype4 = test.filter((data) => data.OptionType == '4')
				const result = calculateValuesforCE(arraytype3, stepSize);

				const resultforPe = calculateValuesforPE(arraytype4, stepSize);



				var arraytype = test
				var type3i = arraytype3.findIndex((data) => nearestValue == data.StrikePrice)
				var type3inew = result.findIndex((data) => nearestValue == data.StrikePrice)

				var type4i = arraytype4.findIndex((data) => nearestValue == data.StrikePrice)
				var type4inew = resultforPe.findIndex((data) => nearestValue == data.StrikePrice)





				var typei = arraytype.findIndex((data) => nearestValue == data.StrikePrice)


				var beforeCount = 3;
				var afterCount = 11;
				var startIndex = Math.max(0, type3inew - beforeCount);
				var endIndex = Math.min(result.length - 1, type3inew + afterCount);
				var filteredArray = result.slice(startIndex, endIndex + 1);
				setAllData(filteredArray)

				var beforeCountnew = 11;
				var afterCount11 = 3;
				var startIndex1 = Math.max(0, type4inew - beforeCountnew);
				var endIndex1 = Math.min(resultforPe.length - 1, type4inew + afterCount11);
				var filteredArray1 = resultforPe.slice(startIndex1, endIndex1 + 1);
				setDataPe(filteredArray1)



				var beforeCountnew18 = 18;
				var afterCountnew18 = 26;
				var startIndex18 = Math.max(0, typei - beforeCountnew18);
				var endIndex18 = Math.min(arraytype.length - 1, typei + afterCountnew18);
				var filteredArray18 = arraytype.slice(startIndex18, endIndex18 + 1);

				const groupedData = arraytype.reduce((acc, obj) => {
					const key = obj.StrikePrice;
					if (!acc[key]) {
						acc[key] = [];
					}
					acc[key].push(obj);
					return acc;
				}, {});


				const output = Object.values(groupedData).map(group => {
					const updatedGroup = group.reduce((acc, obj) => {
						if (obj.OptionType === 3) {
							acc.Touchline.LastTradedPriceopt3 = obj.Touchline.LastTradedPrice;
							acc.CurrentIVopt3 = parseFloat(obj.CurrentIV).toFixed(2);

						} else if (obj.OptionType === 4) {
							acc.Touchline.LastTradedPriceopt4 = obj.Touchline.LastTradedPrice;
							acc.CurrentIVopt4 = obj.CurrentIV;
						}
						return acc;
					}, { ...group[0] });
					return updatedGroup;
				});

				setoptionChain(output)
				let Straddle = output.filter((data) => data.StrikePrice == nearestValue)
				let Straddlecalc = 0
				if (Straddle) {
					Straddle.map((data) => {
						Straddlecalc = Straddlecalc + data.Touchline.LastTradedPriceopt3 + data.Touchline.LastTradedPriceopt4
					})


				}

				setStraddleval(Straddlecalc)

			}
		}

	}, [newData, stepvalue])



	// React.useMemo(()=> {


	// 	let test = 100; 

	// 	function calculateStrikePrices(testValue) {
	// 		let aboveStrikePrice = null;
	// 		let belowStrikePrice = null;

	// 		alldata.forEach(data => {
	// 			if (data.Touchline.LastTradedPrice <= testValue && (belowStrikePrice === null || data.Touchline.LastTradedPrice > belowStrikePrice)) {
	// 				belowStrikePrice = data.StrikePrice;
	// 			}
	// 			if (data.Touchline.LastTradedPrice >= testValue && (aboveStrikePrice === null || data.Touchline.LastTradedPrice < aboveStrikePrice)) {
	// 				aboveStrikePrice = data.StrikePrice;
	// 			}
	// 		});

	// 		return { above: aboveStrikePrice, below: belowStrikePrice };
	// 	}

	// 	let result = calculateStrikePrices(test);

	// 	console.log(result,"result");




	// 	let newval = alldata.filter((data)=> data.StrikePrice %  stepvalue == 0)
	// 	 alldata.forEach(elementA => {
	// 		const correspondingElementB = newval.find(elementB => elementB.StrikePrice === elementA.StrikePrice);
	// 		if (correspondingElementB) {
	// 			elementA.calculatedvalnew = parseFloat(correspondingElementB.calculatedval).toFixed(2);
	// 		}
	// 		else{
	// 			elementA.calculatedvalnew = '0'
	// 		}
	// 	});
	// 	setAllData(alldata)



	// 	let newvaldataPe = dataPe.filter((data)=> data.StrikePrice %  stepvalue == 0)
	// 	dataPe.forEach(elementA => {
	// 	   const correspondingElementBdataPe = newvaldataPe.find(elementB => elementB.StrikePrice === elementA.StrikePrice);
	// 	   if (correspondingElementBdataPe) {
	// 		   elementA.calculatedvalnew = parseFloat(correspondingElementBdataPe.calculatedval).toFixed(2);
	// 	   }
	// 	   else{
	// 		   elementA.calculatedvalnew = '0'
	// 	   }
	//    });
	//    setDataPe(dataPe)








	// },[stepvalue,alldata,dataPe])


	// React.useMemo(()=>{

	// },[])



	React.useEffect(() => {
		getDate()
	}, [symbol])

	const getDate = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `getexpirydata?Name=${symbol}`)
			.then((res) => {
				const formattedDates = res.data.ContractExpirations.map(date => {
					return new Date(date).toISOString().split('T')[0];
				});
				setExpiryDate(formattedDates[0])
				setDatearray(formattedDates)
			})
			.catch((err) => {
				console.log(err, "eeeeeeee");
			})
	}



	return (
		<div>
			<Box className='page_main'>
				<MiniDrawer type={1} />
				<Box className='content-wrapper'>
					<Box className='customCard'>
						{/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
							<Box className='flex-center'>
								<InputLabel id="demo-simple-select-label" className="fz-14 mr-8">Symbol:</InputLabel>
								<Select labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={symbol}
									onChange={(e) => { setSymbol(e.target.value) }}
									name="email"
									className='select'
									displayEmpty
									autoWidth>
									<MenuItem value="NIFTY">Nifty</MenuItem>
									<MenuItem value="BANKNIFTY">BankNifty</MenuItem>
									<MenuItem value="FINNIFTY">FinNifty</MenuItem>
								</Select>
							</Box>

							<Box className='flex-center' >
								<InputLabel id="demo-simple-select-label" className="fz-14 mr-8">Step:</InputLabel>
								<Select labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={stepvalue}
									onChange={(e) => {setStepvalue(e.target.value);setValue(true)}}
									name="email"
									className='select'
									displayEmpty
									autoWidth>
									<MenuItem value="50">50</MenuItem>
									<MenuItem value="100">100</MenuItem>
									<MenuItem value="150">150</MenuItem>
									<MenuItem value="200">200</MenuItem>
									<MenuItem value="250">250</MenuItem>
									<MenuItem value="300">300</MenuItem>
								</Select>
							</Box>
				
							<Box className='flex-center'>
								<InputLabel id="demo-simple-select-label" className="fz-14 mr-8">Expiry Date:</InputLabel>
								<FormControl fullWidth>
									<Select style={{ backgroundColor: '#F3F6F9', maxHeight: '42px', fontSize: '15px', }}
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										className='select'
										name='installment'
										value={expirydate}
										onChange={(e) => setExpiryDate(e.target.value)}>
										{datearray && datearray?.length > 0 && datearray.map((date, index) => {
											return <MenuItem key={index} value={date}>{date ? moment(date).format('DD-MMM-YYYY') : ""}</MenuItem>

										})}
									</Select>
								</FormControl>
							</Box>

							<Box className='flex-center'>
								<span className="fz-14 mr-8">Spread:</span>
								<input
									type="text"
									className="input"
								/>
							</Box>

							<Box className='flex-center'>
								<span className="fz-14 mr-8">Spot Price:</span>
								<input
									type="text"
									disabled
									value={spotprice}
									className="input"
								/>
							</Box>

							<Box className='flex-center'>
								<span className="fz-14 mr-8">STRD:</span>
								<input
									type="text"
									disabled
									value={straddleval ? parseFloat(straddleval).toFixed(2):""}
									className="input"
								/>
								</Box>
						</div> */}
						<div className="container">
							<div className="row">
								<div className="col">
									<div className='flex-center'>
										<InputLabel id="demo-simple-select-label" className="fz-14 mr-8">Symbol:</InputLabel>
										<Select labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={symbol}
											onChange={(e) => { setSymbol(e.target.value) }}
											name="email"
											className='select'
											displayEmpty
											autoWidth>
											<MenuItem value="NIFTY">Nifty</MenuItem>
											<MenuItem value="BANKNIFTY">BankNifty</MenuItem>
											<MenuItem value="FINNIFTY">FinNifty</MenuItem>
										</Select>
									</div>
								</div>
								<div className="col">
									<div className='flex-center'>
										<InputLabel id="demo-simple-select-label" className="fz-14 mr-8">Step:</InputLabel>
										<Select labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={stepvalue}
											onChange={(e) => { setStepvalue(e.target.value); setValue(true) }}
											name="email"
											className='select'
											displayEmpty
											autoWidth>
											<MenuItem value="50">50</MenuItem>
											<MenuItem value="100">100</MenuItem>
											<MenuItem value="150">150</MenuItem>
											<MenuItem value="200">200</MenuItem>
											<MenuItem value="250">250</MenuItem>
											<MenuItem value="300">300</MenuItem>
										</Select>
									</div>
								</div>
								<div className="col">
									<div className='flex-center'>
										<InputLabel id="demo-simple-select-label" className="fz-14 mr-8">Expiry Date:</InputLabel>
										<Select style={{ backgroundColor: '#F3F6F9', maxHeight: '42px', fontSize: '15px', }}
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											className='select'
											name=''
											value={expirydate}
											onChange={(e) => setExpiryDate(e.target.value)}>
											{datearray && datearray?.length > 0 && datearray.map((date, index) => {
												return <MenuItem key={index} value={date}>{date ? moment(date).format('DD-MMM-YYYY') : ""}</MenuItem>
											})}
										</Select>
									</div>
								</div>

								<div className="col">
									<div className='flex-center'>
										<span className="fz-14 mr-8">Spread:</span>
										<input
											type="text"
											className="input"
										/>
									</div>
								</div>
								<div className="col">
									<div className='flex-center'>
										<span className="fz-14 mr-8">Spot Price:</span>
										<input
											type="text"
											disabled
											value={spotprice}
											className="input"
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<div className='flex-center'>
										<span className="fz-14 mr-8">STRD:</span>
										<input
											type="text"
											disabled
											value={straddleval ? parseFloat(straddleval).toFixed(2) : ""}
											className="input"
										/>
									</div>
								</div>
							</div>
						</div>

						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<TableContainer component={Paper} className='tableviewnew'>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell style={{ width: '10%',whiteSpace:'nowrap'}}>Strike Price CE</TableCell>
											<TableCell>ButterFly CE</TableCell>
											<TableCell>STRD</TableCell>
											<TableCell>BTFSPRD</TableCell>
										</TableRow>
									</TableHead>
									{alldata && alldata?.length > 0 ? alldata.map((row, i) => (
										<TableBody>
											<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell  className={row && row.StrikePrice === nearval ? 'themeBtnblue' : ""} align="right">{row.StrikePrice}</TableCell>
												{/* parseFloat(row.calculatedval).toFixed() */}
												<TableCell align="center">{row.calculatedValue ? parseFloat(row.calculatedValue).toFixed(2) : "-"}</TableCell>
												{/* <TableCell align="center">{row.calculatedval ? parseFloat(row.calculatedval).toFixed(2) : "-"}</TableCell> */}
												{/* <TableCell align="center">{row.calculatedvalnew && row.calculatedvalnew == '0' ? '0' :row.calculatedvalnew ? parseFloat(row.calculatedvalnew).toFixed(2) : !row.calculatedvalnew ? parseFloat(row.calculatedval).toFixed(2):"0"}</TableCell> */}
												{/* <TableCell align="center">{row.calculatedval ? row.calculatedval : "-"}</TableCell> */}
												{/* <TableCell align="center">{row && row.Touchline && row.Touchline.LastTradedPrice == '0' ? '0' : parseFloat(row.Touchline.LastTradedPrice).toFixed(2)}</TableCell> */}
												{/* <TableCell  align="center">{row.value ? row.value:"-"}</TableCell> */}
												{/* <TableCell  align="center">{row.LastTradedPrices ? row.LastTradedPrices:"-"}</TableCell> */}
												<TableCell align="center">-</TableCell>
												<TableCell align="center">-</TableCell>
											</TableRow>
										</TableBody>
									)) : ""}
								</Table>
							</TableContainer>

							<TableContainer component={Paper} className='tableviewnew1'>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell style={{ width: '10%',whiteSpace:'nowrap'}}>Strike Price PE</TableCell>
											<TableCell>ButterFly PE</TableCell>
										</TableRow>
									</TableHead>
									{dataPe && dataPe?.length > 0 ? dataPe.map((row, i) => (
										<TableBody>
											<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell className={row && row.StrikePrice === nearval ? 'themeBtnblue' : ""} align="center">{row.StrikePrice}</TableCell>
												{/* <TableCell align="center">{row && row.Touchline && row.Touchline.LastTradedPrice == '0' ? '0' : parseFloat(row.Touchline.LastTradedPrice).toFixed(2)}</TableCell> */}
												<TableCell align="center">{row.calculatedValue ? parseFloat(row.calculatedValue).toFixed(2) : "-"}</TableCell>
												{/* <TableCell align="center">{row.calculatedval ? parseFloat(row.calculatedval).toFixed(2) : "-"}</TableCell> */}
												{/* <TableCell align="center">{row.calculatedvalnew && row.calculatedvalnew == '0' ? '0' :row.calculatedvalnew ? parseFloat(row.calculatedvalnew).toFixed(2) : !row.calculatedvalnew ? parseFloat(row.calculatedval).toFixed(2):"0"}</TableCell> */}
											</TableRow>
										</TableBody>
									)) : ""}
								</Table>
							</TableContainer>

							<TableContainer component={Paper} className='tableviewnew2'>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell style={{ width: '10%',whiteSpace:'nowrap'}}>Option Chain</TableCell>
											<TableCell>CE</TableCell>
											<TableCell>IV</TableCell>
											<TableCell style={{ width: '10%',whiteSpace:'nowrap'}}>Change IV</TableCell>
											<TableCell>PE</TableCell>
											<TableCell>IV</TableCell>
											<TableCell style={{ width: '10%',whiteSpace:'nowrap'}}>Change IV</TableCell>
										</TableRow>
									</TableHead>
									{optionChain && optionChain?.length > 0 ? optionChain.map((row, i) => {
										return (

											<TableBody>
												<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
													<TableCell className={row && row.StrikePrice === nearval ? 'themeBtnblue' : ""} align="right">{row.StrikePrice}</TableCell>
													<TableCell align="center">{row.Touchline && row.Touchline.LastTradedPriceopt3 == '0' || !row.Touchline.LastTradedPriceopt3 ? '0' : parseFloat(row.Touchline.LastTradedPriceopt3).toFixed(2)}</TableCell>


													{/* <TableCell align="center">{parseFloat(Math.random()).toFixed(2)}</TableCell> */}
													<TableCell align="center">{'0'}</TableCell>
													{/* <TableCell align="center">{row.CurrentIVopt3 ? row.CurrentIVopt3 : "-"}</TableCell> */}
													<TableCell align="center">-</TableCell>


													<TableCell align="center">{row.Touchline && row.Touchline.LastTradedPriceopt4 == '0' || !row.Touchline.LastTradedPriceopt4 ? '0' : parseFloat(row.Touchline.LastTradedPriceopt4).toFixed(2)}</TableCell>
													{/* <TableCell align="center">{row.CurrentIVopt4 ? row.CurrentIVopt4 : "-"}</TableCell> */}
													{/* <TableCell align="center">{parseFloat(Math.random()).toFixed(2)}</TableCell> */}
													<TableCell align="center">{'0'}</TableCell>

													<TableCell align="center">-</TableCell>
												</TableRow>

											</TableBody>
										)



									}) : ""}
								</Table>
							</TableContainer>
						</div>
					</Box>
				</Box>
			</Box>
		</div>
	);
};

export default ButterFly;